<template>
  <!-- 二维码 弹框 -->
  <el-dialog
    v-if="dialogVisible"
    append-to-body
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
  >
    <div ref="qrCodeDiv" style="width: 250px; margin: 30px auto"></div>
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "二维码",
      data: "",
    };
  },
  watch: {
    dialogVisible(p) {
      if (!p) {
        this.$emit("closeCallback");
      }
    },
  },
  methods: {
    start(data, title) {
      this.data = data; // 二维码内容
      this.title = title; // 标题
      this.dialogVisible = true;

      let that = this;
      this.$nextTick(function () {
        that.$refs.qrCodeDiv.innerHTML = "";
        new QRCode(that.$refs.qrCodeDiv, {
          text: data, // 二维码内容
          width: 250,
          height: 250,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          // correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
